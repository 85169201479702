import React from "react";
import ConvertKitForm from 'convertkit-react';

const Signup = () => {
  const config = {
    formId: 2351481,
    template:'clare',
    emailPlaceholder: 'Ingrese correo electrónico',
    submitText: 'Suscribirme',
    hideName: true,
  }

  return (
    <ConvertKitForm {...config} />
  )
}
export default Signup
