/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Signup from "../components/signup"

export const pageQuery = graphql`
  query SubscribeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      limit: 6
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const SubscribePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, excerpt } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""

  return (
    <Layout className="page">
      <Seo description={excerpt} image={Image} />
      <div style={{ flex: 1, justifyContent: "center" }}>
        <h2>
          Gracias por tu interés en Undebtme. Tu mejor aliado para el pago de
          deudas
          <span role="img" aria-label="thanks">
            🙏
          </span>
        </h2>
        <h3>
          Estas a un solo paso de tener control completo de tus finanzas
          personales
          <span role="img" aria-label="money">
            🤑
          </span>
          <span role="img" aria-label="rocket">
            🚀
          </span>
          .
        </h3>
        <p>
          Comienza a disfrutar de los beneficios de las finanzas automatizadas.{" "}
          <span role="img" aria-label="thanks">
            🚀
          </span>
          .
        </p>
        <p>
          Ingresa tu correo electrónico para ser parte de nuestro círculo
          exclusivo de usuarios.
        </p>
      </div>

      <div style={{ paddingTop: 50 }}>
        <Signup />
      </div>
    </Layout>
  )
}

export default SubscribePage
